import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Title from '../../components/title';

import {withStyles} from '@material-ui/core/styles';

import img1 from '../../images/cennik/transport-na-lawecie/1.jpg'

const styles = {
  header: {fontSize: 24, marginBottom: "0.8em"},
  paragraph: {marginBottom: "2em"},
  text: {fontSize: "12px", textAlign: "justify"},
  li: {margin: "auto"},
  greyHeader: {color: "#888", margin: "1rem 0"}
};

const Paczki = () =>
  (
    <Layout>
      <SEO title="Transport aut z Anglii do Polski na lawecie"
           keywords={[`Paczki`, `Anglia`, `Paczki Polska Anglia`, `Paczki Anglia`, `UK-TRANS`, `Przewozy Okonek`, `Przewozy-Okonek`, `Przewozy.okonek`, `Przeprowadzki anglia.`]}/>
      <Title>TRANSPORT AUT Z ANGLII DO POLSKI NA LAWECIE </Title>
      <div className="container" css={styles.text}>

        <div className="d-flex justify-content-center">
          <img src={img1} alt="TRANSPORT AUT Z ANGLII DO POLSKI NA LAWECIE"/>
        </div>

        <p>
          <div>Oferujemy także transport aut na lawecie, quadów, motocykli między Polską – Anglią – Polską.</div>
          <div>Do Klienta należy wyłącznie wskazanie adresu odbioru, doręczenia oraz przekazanie dokumentów.</div>
        </p>

        <p>Zapytaj o dokładną cenę i dostępność transportu na danym terenie, skontaktuj się z nami poprzez formularz
          kontaktowy lub telefonicznie.</p>

        <h4 css={styles.greyHeader}>TEL. PL +48 513 900 083</h4>
        <h4 css={styles.greyHeader}>TEL. UK 0044 759 818 69 16</h4>

        <div css={styles.paragraph}>Mieszkasz w Anglii i masz swój samochód? Świetnie! Chcesz go przetransportować do
          Polski? To idealnie trafiłeś. Zajmujemy się transportem aut z Anglii do Polski na lawecie. Pomożemy Ci
          przewieźć zakupione auto do kraju. Zapewniamy fachową obsługę, rzetelne wykonanie zadania i terminowość.
          Wystarczy, że się do nas zgłosisz, umówimy się na określone miejsce odbioru auta, przekażesz nam dokumenty i
          my ruszamy w trasę. Nasi kierowcy są doświadczeni i pewni. Zapewniamy bezpieczny przewóz Twojego auta do
          Polski lub z Polski do Anglii. Umawiamy się na konkretny termin, w ramach którego realizujemy zlecone nam
          zadanie. Możesz nam w pełni zaufać i nie martwić się o swój samochód.
        </div>

        <div css={styles.header}>Laweta z Anglii do Polski</div>

        <div css={styles.paragraph}>Nasza flota jest wysokiej jakości, dobre samochody i porządna laweta gwarantują nam
          i Klientom bezproblemowy transport auta z Anglii do Polski. Oprócz samochodów oferujemy również opcję
          transportu motocykli i quadów. Wyjeżdżając nie musisz rezygnować ze swoich przyjemności. Możesz skorzystać z
          naszej oferty i przetransportować swój ukochany motor, bądź samochód. Jako licencjonowany przewoźnik zajmujemy
          się również przewozem osób i zwierząt. Jeśli interesuje Cię ten rodzaj transportu, przejdź do zakładki przewóz
          osób lub przewóz zwierząt. Flota, jaką dysponujemy jest w pełni przygotowana i sprawdzona na dalekie podróże.
          Nasze samochody są komfortowe, a lawety sprawdzone i bezpieczne. Wszystkie nasze pojazdy posiadają niezbędną
          dokumentację, do której Państwo jako nasi Klienci mają pełen wgląd przed wyjazdem. Wiemy jak ważne jest
          samopoczucie podróżujących i komfort psychiczny wynikający z zaufania do przewoźnika transportującego cenne
          dla nas rzeczy.
        </div>

        <div css={styles.header}>Laweta z Polski do Anglii</div>

        <div css={styles.paragraph}>Od wielu lat zajmujemy się przewozem osób i rzeczy pomiędzy Polską i Anglią.
          Wieloletnie doświadczenie pozwala nam na kompleksowe i profesjonalne świadczenie usług. Śmiało możemy
          powiedzieć, że jesteśmy pewną i doświadczoną firmą, która Was nie zawiedzie. Zdajemy sobie sprawę, jak ludzie
          przywiązują się do swoich rzeczy, samochodów czy drobnych przedmiotów. Dlatego możemy tak dobrze wywiązywać
          się ze świadczonych usług. Lubimy naszą pracę i jesteśmy jej w pełni oddani. Dlatego jeśli chcesz
          przetransportować na lawecie swój samochód, zgłoś się do nas i nie narażaj się na niebezpieczeństwo. Zaufaj
          nam i zapewnij sobie spokój.
        </div>
      </div>
    </Layout>
  );

export default withStyles(styles)(Paczki);